import React from "react"

import styles from "../style.module.less"

const EmptyMedia = ({ isVideo, isPhoto }) => {
  return (
    <div className={styles.empty__media}>
      <div className={styles.errorWidth}>
        <div className={styles.empty__media__container}>
          {isVideo && (
            <img
              className={styles.empty__media__img}
              alt="Nenhum favorito"
              src={require("@images/icons/emptyVideo.svg")}
            />
          )}
          {isPhoto && (
            <img
              className={styles.empty__media__img}
              alt="Nenhum favorito"
              src={require("@images/icons/emptyEssay.svg")}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EmptyMedia
