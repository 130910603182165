import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import cx from "classnames"
import { navigate } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import { EssayNotFound } from "./components/EssayNotFound"
import GalleryFeed from "./components/GalleryFeed"
import Header from "./components/Header"
import styles from "./style.module.less"

const Gallery = ({ essayId }) => {
  const [essayData, setEssayData] = useState(null)
  const [essayUpdate, setEssayUpdate] = useState(0)

  const { essay } = useStores()
  const { findById } = essay
  const isDesktop = useMedia({ minWidth: 1025 })

  const handleClick = () => navigate("/ensaios")

  useEffect(() => {
    if (essayId) {
      handleGetEssayById()
    }
  }, [essayId, essayUpdate])

  const handleGetEssayById = useCallback(async () => {
    const data = await findById(essayId, true)

    setEssayData(
      Array.isArray(data)
        ? { error: true, message: translate("notFoundEssay") }
        : data
    )
  }, [])

  const handleUpdate = () => setEssayUpdate(essayUpdate + 1)

  if (!essayData) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "300px",
        }}
      >
        <img
          style={{ maxWidth: "40px" }}
          src={require(`@images/loading.gif`)}
        />
      </div>
    )
  }

  if (essayData.error) {
    return (
      <EssayNotFound
        message={essayData.message}
        handleGoBack={() => navigate("/ensaios")}
      />
    )
  }

  return (
    <TranslateWrapper>
      <div
        className={
          isDesktop
            ? cx("galleryContext", styles.page__center)
            : cx("mc galleryContext")
        }
      >
        <div className={styles.page__content}>
          <Header {...essayData} />
          <GalleryFeed
            data={essayData}
            handleUpdate={
              handleUpdate /* () => setEssayUpdate(essayUpdate + 1) */
            }
          />
        </div>
      </div>
    </TranslateWrapper>
  )
}

export default Gallery
