import { Icon } from "@components/Icons"
import TheaterSwitch from "@components/TheaterSwitch"
import translate from "@src/i18n"
import { Row, Tabs } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import Mansory, { ResponsiveMasonry } from "react-responsive-masonry"
import { useMedia } from "react-use-media"

import styles from "../style.module.less"
import Card from "./Card"
import EmptyMedia from "./EmptyMedia"

const GalleryFeed = ({ data, handleUpdate }) => {
  const [theater, setTheater] = useState(false)
  const [minHeightCol, setMinHeightCol] = useState(463)
  const [mediaData, setMediaData] = useState(data.photos)
  const [isBottom, setIsBottom] = useState(false)
  const [scrolling, setScrolling] = useState(false)

  const isDesktop = useMedia({ minWidth: 1025 })

  useEffect(() => {
    window.onscroll = () => {
      setScrolling(true)
    }
    setInterval(() => {
      if (!scrolling) {
        setScrolling(false)
        handleScroll()
      }
    }, 1000)
    if (isBottom && !scrolling) {
      setIsBottom(false)
    }
  }, [scrolling])

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight

    const innerHeight = window.innerHeight + 15

    if (scrollTop + innerHeight >= scrollHeight) {
      setIsBottom(true)
    }
  }

  const handleTheaterMode = () => {
    setTheater((prevState) => !prevState)
    console.log(theater)
  }

  const handleChangeTab = (key) => {
    if (key === "photos") {
      setMediaData(data.photos)
    }

    if (key === "videos") {
      setMediaData(data.videos)
    }
  }
  /*   const renderTheaterMansory = () => {
    return _map(mediaData?.slice(0, mediaData.length / 3), (item) => {
      item.urlImage = item.urlPhoto
      return (
        <Card
          key={item.id}
          item={item}
          theater={theater}
          setHeightCol={setMinHeightCol}
          isBottom={isBottom}
        />
      )
    })
  } */

  const renderContent = () => {
    const TabName = (props) => {
      const { name, quantity } = props

      return (
        <div className={styles.quantity__container}>
          <span className="essayTab">{name}</span>
          <span className={styles.quantity__number}>{quantity}</span>
        </div>
      )
    }

    return (
      <Tabs
        onTabClick={handleChangeTab}
        className={cx("filterTabs", styles.gallery__feed__tabs)}
      >
        <Tabs.TabPane
          tab={
            <TabName
              name={translate("photos")}
              quantity={_get(data, "photos.length", 0)}
            />
          }
          key="photos"
          className={styles.gallery__feed__tab__photo}
        >
          <>
            <TheaterSwitch
              handleTheaterMode={handleTheaterMode}
              theater={theater}
            />
            {isDesktop ? (
              <div className={cx("tabPhoto", styles.tab__photo)}>
                <Row
                  gutter={[
                    { sm: 12, md: 12, lg: 24 },
                    { sm: 12, md: 12, lg: 24 },
                  ]}
                  // className="pb24"
                  // style={{ justifyContent: "space-between" }}
                >
                  {_map(mediaData, (item) => {
                    item.urlImage = item.urlPhoto

                    return (
                      <Card
                        key={item.id}
                        item={item}
                        theater={theater}
                        heightCol={minHeightCol}
                        setHeightCol={setMinHeightCol}
                        isBottom={isBottom}
                        handleUpdate={handleUpdate}
                      />
                    )
                  })}
                </Row>
              </div>
            ) : (
              <ResponsiveMasonry
                columnsCountBreakPoints={
                  theater
                    ? { 320: 4, 474: 4, 750: 4, 1024: 4 }
                    : { 320: 1, 474: 1, 750: 2, 1024: 2 }
                }
              >
                <Mansory
                  gutter={theater ? 5 : 32}
                  className="mansoryClassGalleryFeedTheater"
                >
                  {_map(mediaData, (item) => {
                    item.urlImage = item.urlPhoto
                    return (
                      <Card
                        key={item.id}
                        item={item}
                        theater={theater}
                        setHeightCol={setMinHeightCol}
                        isBottom={isBottom}
                        handleUpdate={handleUpdate}
                      />
                    )
                  })}
                </Mansory>
              </ResponsiveMasonry>
            )}

            {mediaData.length === 0 && (
              <EmptyMedia message="emptyStateEssayPhotos" isPhoto={true} />
            )}
          </>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <TabName
              name={translate("videos")}
              quantity={_get(data, "videos.length", 0)}
            />
          }
          key="videos"
        >
          <>
            <TheaterSwitch
              handleTheaterMode={() => handleTheaterMode()}
              theater={theater}
            />

            <Row
              gutter={[
                { sm: 12, md: 12, lg: 24 },
                { sm: 12, md: 12, lg: 24 },
              ]}
              className=""
            >
              {_map(mediaData, (item) => {
                item.urlImage = item.urlPhoto

                return (
                  <Card
                    key={item.id}
                    item={item}
                    theater={theater}
                    heightCol={minHeightCol}
                    setHeightCol={setMinHeightCol}
                    isBottom={isBottom}
                    isVideo
                  />
                )
              })}
            </Row>
          </>

          {mediaData.length === 0 && (
            <EmptyMedia message="emptyStateEssayVideos" isVideo={true} />
          )}
        </Tabs.TabPane>
      </Tabs>
    )
  }

  return (
    <>
      <Icon
        name="HeartFilled"
        className={cx("f24 french-rose w0 h0 absolute", styles.hiddenIcon)}
      />
      {renderContent()}
    </>
  )
}

export default GalleryFeed
