import { UserOutlined } from "@ant-design/icons"
import { CategoryByNumber } from "@modules/Essay/store"
import { normalizePath } from "@utils/path"
import { capitalizeTheFirstLetterOfEachWord } from "@utils/strings"
import { Avatar } from "antd"
import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import styles from "../style.module.less"
const Header = ({
  model,
  modelNickname,
  modelUrlProfile,
  name,
  category,
  photographer,
  photographerNickname,
  modelUsername,
  photographerUsername,
  totalLikes,
  totalComments,
}) => {
  const pagePhotographer = normalizePath(`/perfil/${photographerUsername}`)
  const pageModel = normalizePath(`/perfil/${modelUsername}`)

  return (
    <div className={styles.header}>
      <Helmet>
        <title>
          Ensaio {`"${name}" de ${modelNickname} (@${modelUsername})`}
        </title>
      </Helmet>
      <div className={styles.header__gallery__container}>
        <div className={styles.header__gallery}>
          <Avatar
            src={`${process.env.S3_IMAGES}/${modelUrlProfile}`}
            className="avatar__essay"
            icon={<UserOutlined />}
          />
          <div>
            <h3 className={styles.header__essay__title}>{name}</h3>
            <div className={styles.header__photographer__model__links}>
              <Link to={pageModel}>
                <span className="">
                  {capitalizeTheFirstLetterOfEachWord(
                    !modelNickname ? model : modelNickname
                  )}
                </span>
              </Link>{" "}
              por{" "}
              <Link to={pagePhotographer}>
                <span
                  className=" "
                  title={capitalizeTheFirstLetterOfEachWord(
                    (!photographerNickname
                      ? photographer
                      : photographerNickname) ||
                      (!modelNickname ? model : modelNickname)
                  )}
                >
                  {capitalizeTheFirstLetterOfEachWord(
                    (!photographerNickname
                      ? photographer
                      : photographerNickname) ||
                      (!modelNickname ? model : modelNickname)
                  )}
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.header__essay__icons}>
          <span className={styles.header__category}>
            {CategoryByNumber[category]}
          </span>
          <div className={styles.header__gallery__likes}>
            <span>
              <img
                className=""
                src={require("@images/icons/heart-full-dark.svg")}
              />{" "}
              {`${totalLikes} `}
            </span>
            <span className={styles.comment}>
              <img
                className=""
                src={require("@images/icons/comment-full-dark.svg")}
                alt="Comentários"
              />{" "}
              {`${totalComments} `}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
