import { PlusCircleFilled } from "@ant-design/icons"
import translate from "@src/i18n"
import { Avatar } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React from "react"
import { useMedia } from "react-use-media"

import styles from "./style.module.css"

const modelsArray = [
  {
    id: 1,
    name: "Ani",
    surname: "Rocha",
    photoURL: require("@images/ani.jpg"),
  },
  {
    id: 2,
    name: "Ana",
    surname: "Alves",
    photoURL: require("@images/ana.jpg"),
  },
  {
    id: 3,
    name: "Laís",
    surname: "Russa",
    photoURL: require("@images/lais.jpg"),
  },
  {
    id: 4,
    name: "Nataly",
    surname: "Moraes",
    photoURL: require("@images/nataly.jpg"),
  },
  {
    id: 5,
    name: "Janis",
    surname: "Vitti",
    photoURL: require("@images/janis.jpg"),
  },
  {
    id: 6,
    name: "Emily",
    surname: "Garcia",
    photoURL: require("@images/emily.jpg"),
  },
  {
    id: 7,
    name: "Janis",
    surname: "Vitti",
    photoURL: require("@images/janis.jpg"),
  },
  {
    id: 8,
    name: "Emily",
    surname: "Garcia",
    photoURL: require("@images/emily.jpg"),
  },
  {
    id: 9,
    name: "Janis",
    surname: "Vitti",
    photoURL: require("@images/janis.jpg"),
  },
  {
    id: 10,
    name: "Emily",
    surname: "Garcia",
    photoURL: require("@images/emily.jpg"),
  },
]

const LikedModels = ({ handleSearch }) => {
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 })
  const isDesktopLarge = useMedia({ minWidth: 1366 })

  const handleActualModel = (id) => {
    alert(`Modelo selecionada: ${id}`)
  }

  return (
    <div className={cx("br4", styles.rectangle)}>
      <h3
        className={cx(
          "f20 pa12 tc br4 br--top fw6 lh-copy bg-alto",
          styles.notRadius,
          styles.colorTitleLiked
        )}
      >
        {translate("mostLiked")}
      </h3>

      <div className={cx("pa12 pt16", styles.listMargin)}>
        <div
          className={cx(
            "flex flex-row",
            styles.listHorizontal,
            styles.justifyDesktop
          )}
        >
          {_map(modelsArray, (item, index) => {
            if (isDesktop && index >= 5) return null

            if (isDesktopLarge && index >= 8) return null

            return (
              <div
                className={cx(
                  "flex flex-column items-center mr12 pb4",
                  styles.avatarMargin
                )}
                key={index}
                onClick={() => handleActualModel(item.id)}
                id={`models${index}`}
              >
                <div className="pointer">
                  <Avatar
                    key={index}
                    src={item.photoURL}
                    className={cx("contain", styles.photoSize)}
                  />
                </div>

                <label className="fw5 lh-copy f12 pt4">{item.name}</label>

                <label className="fw5 lh-copy f12">{item.surname}</label>
              </div>
            )
          })}

          <div className="flex flex-column items-center">
            <div className="pointer">
              <PlusCircleFilled
                className={styles.colorIcon}
                onClick={handleSearch}
              />
            </div>

            <label className="fw5 lh-copy f12 pt4">
              {translate("viewMore")}
            </label>

            <label className="fw5 lh-copy f12 ttl">{translate("all")}</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LikedModels
