import translate from "@src/i18n"
import React from "react"

import styles from "../style.module.less"

export const EssayNotFound = ({ handleGoBack, message }) => {
  return (
    <div className={styles.notFound}>
      <p>{message}</p>

      <a href="/feed">{translate("pageNotFound.goBack")}</a>
    </div>
  )
}
